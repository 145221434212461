@import "../../assets/styles/variables.scss";

//TODO: move this to a global component so account has access too
:global {
  h1 {
    font-family: getThemeValue('heading-font');
    font-size: getThemeValue('heading-font-size');
    font-weight: normal;
    line-height: getThemeValue('heading-line-height');
    color: getThemeValue('heading-font-color');
    margin: 24px 0;
  }

  h4 {
    font-size: getThemeValue('subtitle-font-size');
    font-family: getThemeValue('subtitle-font');
  }

  .filler {
    flex: 1;
  }

  .card-shadow {
    box-shadow: getThemeValue('card-box-shadow');
  }

  #intercom-speak-agent-button, #intercom-container {
    display: None;
  }

  .red-text {
    color: getThemeValue('font-color-negative') !important;
  }

  .alert {
    padding: getThemeValue('alert-padding');
    margin-bottom: getThemeValue('alert-margin-bottom');
    border: getThemeValue('alert-border');
    border-radius: 4px;
    font-family: getThemeValue('font');
    font-size: getThemeValue('alert-font-size');

    &.alert-danger {
      background-color: getThemeValue('alert-danger-background-color');
      border-color: getThemeValue('alert-danger-border-color');
      color: getThemeValue('alert-danger-font-color');
    }

    &.alert-warning {
      background-color: getThemeValue('alert-warning-background-color');
      border-color: getThemeValue('alert-warning-border-color');
      color: getThemeValue('alert-warning-font-color');
    }

    &.alert-success {
      background-color: getThemeValue('alert-success-background-color');
      border-color: getThemeValue('alert-success-border-color');
      color: getThemeValue('alert-success-font-color');
    }
  }

  .selectable {
    user-select: initial;
  }

  .unselectable {
    user-select: none;
  }

  .hidden {
    display: none;
  }

  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    color: #00AEE4;

    &:focus {
      outline: none;
    }
  }

  button {
    &:focus {
      outline: none;
    }
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  * html .clearfix             { zoom: 1; } /* IE6 */
  *:first-child+html .clearfix { zoom: 1; } /* IE7 */

  html {
    body {
      font-family: getThemeValue('font');
      font-size: getThemeValue('font-size');
      color: getThemeValue('font-color');
      font-weight: 400;
      line-height: 1.42857143;
    }
  }
}

.content {
  color: black;
  font-family: getThemeValue('font');
}

:global {
  .touch {
    -webkit-overflow-scrolling: touch;

    &.no-touch {
      -webkit-overflow-scrolling: auto;
    }
  }
}

.portalContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  @media (max-width: $menu-toggle-width) {
    overflow-y: auto;

    .navbarHeader {
      float: none;
    }
  }

  .portalContent {
    position: absolute;
    left: 250px;
    right: 0;
    bottom: 0;
    top: 50px;
    background: getThemeValue('content-background-color');
    overflow: auto;

    &.inApp {
      left: 0;
      top: 0;
    }

    @media (max-width: $menu-toggle-width) {
      left: 0;
      z-index: 1003;
    }
  }

  .hideOnSmall {
    @media(max-height: 668px) {
      display: none;
    }
  }
}

.notificationsContainer {
  position: absolute;
  right: 24px;
  max-width: 270px;
  width: 100%;
  z-index: 9001;
  margin-bottom: 0px;
  border-radius: 0px;

  i {
    font-size: 20px;
    float: right;
    cursor: pointer;
  }

  :global {
    .react-progress-bar {
      @media (max-width: $menu-toggle-width) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    .react-progress-bar-spinner {
      top: 65px;
      right: 15px;
      display: block;
      position: fixed;

      @media (max-width: $menu-toggle-width) {
        top: 8px;
        right: 16px;
      }

      .react-progress-bar-spinner-icon {
        display: none;
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        border: solid 2px transparent;
        border-top-color: #29d;
        border-left-color: #29d;
        border-radius: 50%;
        -webkit-animation: react-progress-spinner 400ms linear infinite;
        animation: react-progress-spinner 400ms linear infinite;
      }
    }

    .react-progress-bar-hide {
      transition: none;
    }

    .react-progress-bar-percent {
      height: 2px;
      background: #344f64;
      transition: all 200ms ease;
      background: $dark-blue;
      box-shadow: 0 0 10px $dark-blue, 0 0 5px $dark-blue;

      &.react-progress-bar-hide {
        transaction: none;
      }

      @media (max-width: $menu-toggle-width) {
        background: $yoco-blue;
        box-shadow: 0 0 10px $yoco-blue, 0 0 5px $yoco-blue;
      }
    }

    .success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
      margin: 0px;
      border-radius: 0px;
    }
    .info {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
      margin: 0px;
      border-radius: 0px;
    }
    .warning {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;
      margin: 0px;
      border-radius: 0px;
    }
    .danger {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      margin: 0px;
      border-radius: 0px;
    }
  }
}
