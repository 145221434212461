@import "../../assets/styles/variables.scss";

.mainNavContainer {
  background: getThemeValue('menu-container-background');
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  &.shown {
    z-index: 5000;
    
    .navbarNav {
      left: 0;
      max-width: 270px;
    }
  }

  .navbarOverlay {
    background: transparentize(black, 0.5);
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media (max-width: $menu-toggle-width) {
    display: block;
    background: getThemeValue('menu-background-color');
    margin: 0;
    height: 50px;
    min-height: 50px;
    width: 100%;
    z-index: 1002;
    position: relative;

    .navbarHeader {
      .navbarBrand {
        padding: 13px 38px;
        color: getThemeValue('menu-font-color');
        font-color: getThemeValue('menu-font-size');
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
        padding: 13px 20px;
        i {
          line-height: 1;
        }
      }

      .mobileNavToggle {
        position: absolute;
        top: 50%;
        margin-top: -20px; // Half height of icon
        margin-left: 12px;
        width: 111px;
        cursor: pointer;
      }
    }

    // This is the responsive Navbar named badly
    .responsiveNavbar {
      background: $White;
      -webkit-transition: left 200ms;
      padding: 20px 0px;
      margin: 0;
      margin-top: 50px;
      float: none !important;
      width: 270px;
      height: initial;
      top: 0;
      bottom: 0;
      position: fixed;
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      &.shown {
        left: 0;
        max-width: 270px;
      }

      li {
        display: block;

        li.submenuItem {
          background-color: getThemeValue('submenu-background-color');
        }

        .menuItem, a {
          background: getThemeValue('menu-background-color');
          color: getThemeValue('menu-font-color');
          text-transform: getThemeValue('menu-text-transform');
          font-family: getThemeValue('font');
          font-weight: getThemeValue('menu-font-weight');
          font-size: getThemeValue('menu-font-size');
          cursor: pointer;

          span, a {
            &.menuText {
              flex: 1;
            }
          }

          .bottomBorder {
            border-bottom: getThemeValue('menu-bottom-border');
          }
        }

        &.active {
          background-color: getThemeValue('menu-active-background-color');
          color: getThemeValue('menu-active-font-color');

          a {
            color: getThemeValue('menu-active-font-color');
            font-weight: getThemeValue('menu-active-font-weight');
          }
        }

      }

      .bottomBorder {
        border-bottom: getThemeValue('menu-bottom-border');
      }
    }
  }

  .tagText {
    color: $White;
    padding-right: 5px;
    font-size: 10px;
    float: right;
  }

  .fixedContainer {
    margin: 38px 0;
    text-align: center;

    @media(max-height: 668px) {
      margin: 16px 0 32px;
    }

    @media(max-width: $menu-toggle-width) {
      display: none;
    }

    &.bottomContainer {
      margin: 20px 0;
      position: relative;
      align-items: center;
      width: 250px;

      &.bottom {
        bottom: 0;
      }
    }
  }

  .supportMenu {
    position: relative;
  }

  .spacer {
    flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
  }

  ul {
    list-style: none;
    font-size: getThemeValue('menu-font-size');
    font-family: getThemeValue("menu-font");
    padding: 0;
    height: 100%;
    display: block;
    z-index: 2;
    overflow-y: auto;
    margin-bottom: 0;

    li {
      .menuItem {
        height: 100%;
        padding: 10px 12px;
      }
    }

    &.innerNavHidden {
      display: none;
    }

    &.innerNavShown, &.active {
      display: block;

      .hidden {
        display: none;
      }

      li.submenuItem {
        display: flex;
        align-items: center;
        padding: 4px 8px 4px 16px;

        .bullet {
          &.active {
            box-shadow: 0 0 2px 2px rgba(getThemeValue('submenu-active-font-color'), 0.5);
            background: getThemeValue('submenu-active-font-color');
          }

          height: 6px;
          width: 6px;
          border-radius: 3px;
          margin-right: 12px;
          background: getThemeValue('submenu-font-color');
        }

        a {
          color: getThemeValue('submenu-font-color');
          padding: 6px 0;
          display: block;
          text-transform: capitalize;
          font-family: getThemeValue('submenu-font');
          font-weight: getThemeValue('submenu-font-weight');
          text-decoration: none;
          background: none;
          flex: 1;
        }

        &.innerActive {
          color: getThemeValue('submenu-active-font-color');
          background: getThemeValue('submenu-active-background-color');

          a {
            font-weight: getThemeValue('submenu-active-font-weight');
          }
        }

      }
    }

    .additionalLabel {
      border-radius: 4px;
      font-weight: 600;
      font-size: 11px;
      color: white;
      padding: 1px 20px;
      line-height: 22px;
    }

    li.flexItem {
      display: inline-block;
      flex: 1;
      background: $dark-blue;
    }

    li {
      .menuItem, a {
        color: getThemeValue('menu-font-color');
        display: block;
        font-family: getThemeValue('menu-font');
        font-weight: getThemeValue('menu-font-weight');
        text-decoration: none;
        margin: 0;
        background: getThemeValue('menu-background-color');
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        i {
          line-height: 1;
          margin-right: 16px;
          height: 24px;
        }

        span, a {
          &.menuText {
            flex: 1;
          }
        }
      }

      &.active {
        .menuItem, a {
          background: getThemeValue('menu-active-background-color');
          font-family: getThemeValue('menu-active-font');
          font-weight: getThemeValue('menu-active-font-weight');
          color: getThemeValue('menu-active-font-color');
        }

        span {
          font-family: getThemeValue('menu-active-font');
        }
      }

      .bottomBorder {
        border-bottom: getThemeValue('menu-bottom-border');
      }
    }
  }
}

.secondNavContainer {
  background: getThemeValue('top-bar-background-color');
  color: getThemeValue('top-bar-font-color');
  margin-left: 250px;
  top: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
  font-family: getThemeValue('top-bar-font');
  font-size: 13px;
  white-space: nowrap;
  padding: 8px getThemeValue('content-padding-size');
  display: flex;
  align-items: center;

  a {
    color: getThemeValue('top-bar-font-color') !important;
    text-decoration: none !important;

    &:hover {
      color: getThemeValue('top-bar-font-color') !important;
      text-decoration: none !important;
    }
  }

  .currentPathContainer {
    position: relative;
    text-transform: capitalize;
    white-space: nowrap;
    display: inline-block;
    height: 50px;
    font-size: 14px;
    width: 100%;
    flex: 1;

    i {
      margin-right: 16px;
      margin-left: 18px;
      font-size: 32px;
      line-height: 50px;
      height: 50px;
      display: inline-block;
      position: relative;
      top: 4px;
    }

    .currentPathName {
      display: inline-block;
      position: relative;
      line-height: 50px;
      font-size: 13px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  a {
    &.userName {
      display: flex;
      align-items: center;

      i {
        height: 24px;
        line-height: 24px;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.11);
        cursor: pointer;
      }
    }
  }

  .referralButton {
    background: getThemeValue('button-quaternary-background-color');
    color: getThemeValue('button-quaternary-font-color');
    border-radius: getThemeValue('button-border-radius');
    font-size: getThemeValue('button-font-size');
    font-weight: getThemeValue('button-font-weight');
    padding: 0 17px;
    display: inline;
    border: none;
    vertical-align: top;
    min-width: 100px;
    line-height: 36px;
    cursor: pointer;
  }

  @media (max-width: $menu-toggle-width) {
    display: none;
    line-height: 40px;
  }
}